import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, Box, CircularProgress, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RenderClozePassageQuestion from './RenderClozePassageQuestion';
import RenderMultipleChoiceQuestion from './RenderMultipleChoiceQuestion';
import RenderOrderingQuestion from './RenderOrderingQuestion';
import RenderDragNDropQuestion from './RenderDragNDropQuestion';
import RenderReadPassageQuestion from './RenderReadPassageQuestion';
import NextQuestion from './NextQuestion';
import {
  getStepQuestion,
  getMode,
  stepCompleted
} from '../../../clients/rotr-client';
import { UUID } from 'crypto';
import StepHeader from './StepHeader';

interface Option {
  optionId: string;
  optionContent: string;
}

type Mode = 'practice' | 'timed';

interface Question {
  attemptID: UUID;
  content: string;
  instruction: string;
  mediaURL: string;
  options: Option[];
  questionID: UUID;
  questionNumber: number;
  rules: string[];
  token: string;
  totalQuestions: number;
  type: string;
  selectLimit: number;
}

const Quiz: React.FC = () => {
  const { stepId } = useParams<{ stepId: UUID | undefined }>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [question, setQuestion] = useState<Question | null>(null);
  const questionRef = useRef(question);
  const [loading, setLoading] = useState(true);
  const [isBouncing, setIsBouncing] = useState(false);
  const [score, setScore] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [mode, setMode] = useState<Mode>('practice');
  const [startTimeSecs, setStartTimeSecs] = useState<number | null>(null);
  const [timerRunning, setTimerRunning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [scoreAnimations, setScoreAnimations] = useState<
    Array<{ id: number; value: number }>
  >([]);
  const navigate = useNavigate();
  const isDevelopment =
    process.env.REACT_APP_URL !== 'https://rotr.navity.com.au';

  const updateScore = (value: number) => {
    if (value > 0) {
      setIsBouncing(true);
      setScore((prevScore) => prevScore + value);
      setTimeout(() => {
        setIsBouncing(false);
      }, 2000);
    }
  };

  interface ScorePointAnimationProps {
    value: number;
    index: number;
    onComplete: () => void;
  }

  const ScorePointAnimation: React.FC<ScorePointAnimationProps> = ({
    value,
    index,
    onComplete
  }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onComplete();
      }, 2000 + index * 100);

      return () => {
        clearTimeout(timer);
      };
    }, [value, index, onComplete]);

    const animationDelay = `${index * 0.1}s`;

    return (
      <div className='score-animation' style={{ animationDelay }}>
        {`+${value}`}
      </div>
    );
  };

  const fetchQuestions = async (
    token: string | null = null,
    stepId: UUID | undefined
  ) => {
    setTimerRunning(false);
    if (stepId) {
      try {
        const response = await getStepQuestion(stepId, token);
        if (response && response.questionID && response.attemptID) {
          const newQuestion: Question = {
            questionID: response.questionID,
            attemptID: response.attemptID,
            type: response.type,
            instruction: response.instruction,
            rules: response.rules ? [response.rules] : [],
            mediaURL: response.mediaURL || '',
            options: Array.isArray(response.options)
              ? response.options.map((option: any) => ({
                  optionId: option.id,
                  optionContent: option.content || ''
                }))
              : [],
            questionNumber: response.questionNumber || 0,
            token: response.token || '',
            totalQuestions: response.totalQuestions || 0,
            content: response.content || '',
            selectLimit: response.selectLimit || 0
          };

          setQuestion(newQuestion);
          setLoading(false);
          setTimerRunning(true);
        } else {
          console.error(
            'Invalid response format: missing or invalid question properties'
          );
          setLoading(false);
          setError('Invalid question format received from the server.');
        }
      } catch (error) {
        console.error('Failed to fetch questions:', error);
        setLoading(false);
        setError('Failed to fetch questions. Please try again later.');
      }
    } else {
      console.error('Step ID is missing');
      setLoading(false);
      setError('Step ID is missing');
    }
  };

  const fetchMode = async (stepId: UUID | undefined) => {
    const response = await getMode(stepId);
    if (response.mode === 'practice') {
      setMode('practice');
    }
    if (response.mode === 'timed' && response.timeLimitSeconds) {
      setMode('timed');
      setStartTimeSecs(response.timeLimitSeconds);
    }
  };

  const completeQuiz = async (timerComplete = false) => {
    let timeTaken;
    setLoading(true);
    if (mode === 'timed' && startTimeSecs) {
      if (timerComplete) {
        timeTaken = startTimeSecs;
      } else {
        timeTaken = startTimeSecs - remainingTime;
      }
    }
    await stepCompleted(questionRef.current?.attemptID, timeTaken);
    setGameOver(true);
  };

  const handleNextQuestion = async () => {
    if (question) {
      if (question.questionNumber + 1 === question.totalQuestions) {
        completeQuiz();
      } else {
        await fetchQuestions(question.token, stepId);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSubmitted(false);
      }
    }
  };

  const renderQuestion = () => {
    if (!question) {
      return <Typography variant='h6'>Loading...</Typography>;
    }

    switch (question.type) {
      case 'CLOZE':
        return (
          <RenderClozePassageQuestion
            attemptId={question.attemptID}
            questionId={question.questionID}
            currentQuestion={question}
            currentQuestionIndex={currentQuestionIndex}
            submitted={submitted}
            setSubmitted={setSubmitted}
            handleNextQuestion={handleNextQuestion}
            updateScore={updateScore}
            setTimerRunning={setTimerRunning}
          />
        );
      case 'MCQ':
        return (
          <RenderMultipleChoiceQuestion
            attemptId={question.attemptID}
            questionId={question.questionID}
            currentQuestion={question}
            currentQuestionIndex={currentQuestionIndex}
            submitted={submitted}
            setSubmitted={setSubmitted}
            handleNextQuestion={handleNextQuestion}
            updateScore={updateScore}
            setTimerRunning={setTimerRunning}
          />
        );
      case 'SEQUENCE':
        return (
          <RenderOrderingQuestion
            currentQuestion={question}
            currentQuestionIndex={currentQuestionIndex}
            handleNextQuestion={handleNextQuestion}
            updateScore={updateScore}
            setTimerRunning={setTimerRunning}
          />
        );
      case 'DRAGNDROP':
        return (
          <RenderDragNDropQuestion
            attemptId={question.attemptID}
            questionId={question.questionID}
            submitted={submitted}
            setSubmitted={setSubmitted}
            currentQuestion={question}
            currentQuestionIndex={currentQuestionIndex}
            handleNextQuestion={handleNextQuestion}
            updateScore={updateScore}
            setTimerRunning={setTimerRunning}
          />
        );
      case 'READ':
        return (
          <RenderReadPassageQuestion
            attemptId={question.attemptID}
            questionId={question.questionID}
            submitted={submitted}
            setSubmitted={setSubmitted}
            currentQuestion={question}
            currentQuestionIndex={currentQuestionIndex}
            handleNextQuestion={handleNextQuestion}
            updateScore={updateScore}
            setTimerRunning={setTimerRunning}
          />
        );
      default:
        return <Typography variant='h6'>Unknown question type</Typography>;
    }
  };

  useEffect(() => {
    if (stepId) {
      fetchQuestions(null, stepId);
      fetchMode(stepId);
    }
  }, [stepId]);

  useEffect(() => {
    questionRef.current = question;
  }, [question]);

  useEffect(() => {
    if (gameOver) {
      navigate(`/games/shake-down/game-over/${question?.attemptID}`);
    }
  }, [gameOver, navigate, question?.attemptID]);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity='error' sx={{ margin: '20px' }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: 'rgb(22, 26, 29)',
        padding: '0',
        borderRadius: '0',
        flexGrow: '1'
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          padding: { xs: '0 16px', sm: '0 24px' },
          color: 'whitesmoke',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          backgroundColor: '#1d2125',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            flexGrow: 1,
            minHeight: { xs: '300px', sm: '400px' }
          }}
        >
          <StepHeader
            score={score}
            currentQuestionIndex={currentQuestionIndex}
            totalQuestions={question?.totalQuestions || 0}
            mode={mode}
            startTimeSecs={startTimeSecs}
            isBouncing={isBouncing}
            timerRunning={timerRunning}
            onTimeUpdate={setRemainingTime}
            setGameOver={setGameOver}
            completeQuiz={completeQuiz}
          />
          {renderQuestion()}
          {scoreAnimations.map((animation, index) => (
            <ScorePointAnimation
              key={animation.id}
              value={animation.value}
              index={index}
              onComplete={() =>
                setScoreAnimations((current) =>
                  current.filter((a) => a.id !== animation.id)
                )
              }
            />
          ))}
        </Box>
      </Box>
      {isDevelopment && (
        <div style={{ color: 'white' }}>
          {' '}
          *** Question ID: {question?.questionID} ***{' '}
        </div>
      )}
    </Paper>
  );
};

export default Quiz;
