import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled,
  keyframes,
  alpha,
  ListItemIcon
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import RadarIcon from '@mui/icons-material/Radar';
import QuizIcon from '@mui/icons-material/Quiz';

interface TaskCompleteBannerProps {
  payload?: {
    taskComplete?: boolean | null;
    taskTitle?: string;
    taskType?: string;
    nextTask?: {
      task_url?: string;
      task_type?: 'scenario' | 'recite' | 'quiz' | null;
      task_title?: string | null;
    };
  };
}

// New styled components to match the home page design
export const BannerContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '6px',
  left: '6px',
  right: '6px',
  zIndex: 1000,
  borderRadius: '12px',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: alpha('#161A1D', 0.9),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
}));

const CompletedTaskItem = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: '12px',
  background: 'transparent'
}));

const TaskContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1
}));

const TaskActions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

// New keyframes for animations
const tickAnimation = keyframes`
  0% { transform: scale(0); opacity: 0; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

// Updated styled components
const AnimatedBannerContainer = styled(BannerContainer)(({ theme }) => ({
  animation: `${fadeIn} 0.5s ease-out, ${fadeOut} 0.5s ease-in 7.5s`
}));

const AnimatedTaskAltTwoToneIcon = styled(TaskAltTwoToneIcon)(({ theme }) => ({
  animation: `${tickAnimation} 1s ease-out`
}));

const NextTaskBanner = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '6px',
  left: '6px',
  right: '6px',
  zIndex: 1000,
  borderRadius: '12px',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: alpha('#161A1D', 0.9),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
}));

const NextTaskContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1
}));

const NextTaskButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: '8px 20px',
  transition: 'all 0.3s ease',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  boxShadow: `0 4px 10px ${alpha(theme.palette.primary.main, 0.3)}`,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 6px 15px ${alpha(theme.palette.primary.main, 0.4)}`
  }
}));

const TaskCompleteBanner: React.FC<TaskCompleteBannerProps> = ({ payload }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showNextTask, setShowNextTask] = useState(false);

  useEffect(() => {
    if (payload?.taskComplete) {
      const timer = setTimeout(() => {
        setIsOpen(false);
        if (payload.nextTask?.task_url && payload.nextTask?.task_type) {
          setShowNextTask(true);
        }
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [payload?.taskComplete, payload?.nextTask]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleNextTask = () => {
    if (payload?.nextTask?.task_url && payload?.nextTask?.task_type) {
      const { task_url, task_type } = payload.nextTask;
      let path;

      switch (task_type) {
        case 'scenario':
          path = `/practice/${task_url}`;
          break;
        case 'recite':
          path = `/practice/recite/${task_url}`;
          break;
        case 'quiz':
          path = `/games/shake-down/steps/${task_url}`;
          break;
        default:
          console.error('Unknown task type:', task_type);
          return;
      }

      window.location.href = `${window.location.origin}${path}`;
    }
  };

  const getTaskIcon = (taskType: string) => {
    switch (taskType) {
      case 'recite':
        return <RecordVoiceOverIcon fontSize='large' />;
      case 'scenario':
        return <RadarIcon fontSize='large' />;
      case 'quiz':
        return <QuizIcon fontSize='large' />;
      default:
        return <RadarIcon fontSize='large' />;
    }
  };

  if (!payload || !payload.taskComplete || (!isOpen && !showNextTask)) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <NextTaskBanner
          sx={{
            animation: `${fadeIn} 0.5s ease-out, ${fadeOut} 0.5s ease-in`
          }}
        >
          <NextTaskContent>
            <ListItemIcon sx={{ color: 'primary.main', minWidth: 56 }}>
              <AnimatedTaskAltTwoToneIcon
                sx={{ fontSize: '2rem', color: '#4CAF50' }}
              />
            </ListItemIcon>
            <Box sx={{ ml: 2 }}>
              <Typography
                variant='subtitle1'
                sx={{ fontWeight: 'bold', color: '#FFFFFF', textAlign: 'left' }}
              >
                Task Complete
              </Typography>
              {payload.taskTitle && (
                <Typography
                  variant='body2'
                  sx={{ color: '#FFFFFF', textDecoration: 'line-through' }}
                >
                  {payload.taskTitle}
                </Typography>
              )}
            </Box>
          </NextTaskContent>
          <IconButton
            aria-label='close'
            size='small'
            onClick={handleClose}
            sx={{ color: '#FFFFFF' }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </NextTaskBanner>
      )}
      {showNextTask && payload.nextTask && (
        <NextTaskBanner
          sx={{
            animation: `${fadeIn} 0.5s ease-out`
          }}
        >
          <NextTaskContent>
            <ListItemIcon sx={{ color: 'primary.main', minWidth: 56 }}>
              {payload.nextTask.task_type &&
                getTaskIcon(payload.nextTask.task_type)}
            </ListItemIcon>
            <Box sx={{ ml: 2 }}>
              <Typography
                variant='subtitle1'
                sx={{ textAlign: 'left', fontWeight: 'bold', color: '#FFFFFF' }}
              >
                Next Task
              </Typography>
              <Typography variant='body2' sx={{ color: '#FFFFFF' }}>
                {payload.nextTask.task_title || 'Start your next task'}
              </Typography>
            </Box>
          </NextTaskContent>
          <NextTaskButton variant='contained' onClick={handleNextTask}>
            Start
          </NextTaskButton>
        </NextTaskBanner>
      )}
    </>
  );
};

export default TaskCompleteBanner;
