import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import InfoIcon from '@mui/icons-material/Info';
import RuleModal from '../../display-rule/DisplayRule';
import { rules } from '../../rules';

interface RuleDisplayProps {
  inputRules: Array<string>;
  showRuleLink: boolean;
}

const RuleDisplay: React.FC<RuleDisplayProps> = ({
  inputRules,
  showRuleLink
}) => {
  const [modalState, setModalState] = useState<{
    ruleId: string;
    subRuleId?: string;
    subSubRuleId?: string;
    open: boolean;
  }>({
    ruleId: '',
    open: false
  });

  const handleRuleClick = (ruleId: string) => {
    let mainRuleId = ruleId;
    let subRuleId;
    let subSubRuleId;
    if (ruleId.includes('-')) {
      const parts = ruleId.split('-');
      if (parts.length === 2) {
        [mainRuleId, subRuleId] = parts;
      } else if (parts.length === 3) {
        [mainRuleId, subRuleId, subSubRuleId] = parts;
      }
    }
    setModalState({
      ruleId: mainRuleId,
      subRuleId: subRuleId,
      subSubRuleId: subSubRuleId,
      open: true
    });
  };

  const handleCloseModal = () => {
    setModalState((prevState) => ({ ...prevState, open: false }));
  };

  if (inputRules.length === 0) {
    return null;
  }

  return (
    <>
      {showRuleLink && (
        <Box
          sx={{
            backgroundColor: 'rgba(30, 30, 30, 0.8)',
            padding: 2,
            marginTop: 2,
            borderRadius: '5px',
            border: '1px solid #1286A6',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Typography
            variant='body1'
            sx={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              minWidth: '200px'
            }}
          >
            <InfoIcon sx={{ marginRight: 1, color: '#1286A6' }} />
            Tip! Check out the rule(s) to understand where you went wrong.
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {inputRules.map((ruleId) => (
              <>
                {console.log(ruleId)}
                <Button
                  key={ruleId}
                  variant='contained'
                  size='small'
                  onClick={() => handleRuleClick(ruleId)}
                  startIcon={<ChromeReaderModeIcon />}
                  sx={{
                    color: '#fff',
                    borderColor: '#1286A6',
                    '&:hover': {
                      backgroundColor: 'rgba(18, 134, 166, 0.1)',
                      borderColor: '#1286A6'
                    }
                  }}
                >
                  Rule {ruleId}
                </Button>
              </>
            ))}
          </Box>
        </Box>
      )}
      {!showRuleLink && (
        <Typography
          component='span'
          sx={{
            color: 'lightgray',
            marginLeft: '10px',
            fontSize: '0.875rem'
          }}
        >
          Rule{' '}
          {inputRules.map((ruleId, index) => (
            <React.Fragment key={ruleId}>
              {index > 0 && ', '}
              {ruleId}
            </React.Fragment>
          ))}
        </Typography>
      )}
      <RuleModal
        ruleId={modalState.ruleId}
        subRuleId={modalState.subRuleId}
        subSubRuleId={modalState.subSubRuleId}
        open={modalState.open}
        onClose={handleCloseModal}
        rules={rules}
      />
    </>
  );
};

export default RuleDisplay;
